import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCode, 
  faChartLine, 
  faUserTie,
  faBriefcase,
  faHandsHelping,
  faTrophy,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const AboutContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  perspective: 1000px;
`;

const SectionTitle = styled.h2`
  text-align: center;
  color: #1a1a2e;
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 900;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: linear-gradient(45deg, #000, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.02);
    letter-spacing: 3px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(to right, transparent, #000, transparent);
  }
`;

const ProfileImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0,0,0,0.2);
  transition: all 0.4s ease;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(0,0,0,0.3), transparent);
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    transform: scale(1.05) rotate(2deg);
    
    &::before {
      opacity: 1;
    }
  }
`;

const HeroContent = styled.div`
  flex: 1;
  position: relative;
  
  &::before {
    content: '"';
    position: absolute;
    top: -20px;
    left: -30px;
    font-size: 6rem;
    color: rgba(0,0,0,0.1);
    font-family: serif;
  }
`;

const HeroSection = styled.section`
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-bottom: 4rem;
  background: linear-gradient(135deg, #f9f9f9, #f0f0f0);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0,0,0,0.1);
  transition: all 0.4s ease;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle at center, 
      transparent 0%, 
      rgba(0,0,0,0.05) 100%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 25px 50px rgba(0,0,0,0.15);

    &::before {
      opacity: 1;
    }

    ${ProfileImageWrapper} {
      transform: scale(0.95);
    }

    ${HeroContent} {
      transform: translateX(20px);
    }
  }

  @media (max-width: 768px) {
    transform: none;
  }
`;

const ProfileImage = styled.img`
  width: 320px;
  height: 320px;
  object-fit: cover;
  filter: grayscale(20%) brightness(0.9);
  transition: all 0.4s ease;

  &:hover {
    filter: grayscale(0) brightness(1);
  }

  @media (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;

const Name = styled.h1`
  font-size: 3rem;
  color: #1a1a2e;
  margin-bottom: 0.5rem;
  font-weight: 900;
  line-height: 1.2;
  background: linear-gradient(45deg, #000, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Tagline = styled.h2`
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 1.5rem;
  font-weight: 500;
  border-left: 5px solid #000;
  padding-left: 15px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateX(10px);
  }
`;

const ProfessionalJourneySection = styled.section`
  background: linear-gradient(135deg, #f9f9f9, #f0f0f0);
  padding: 3rem 2rem;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0,0,0,0.1);
  margin-bottom: 3rem;
  transform-style: preserve-3d;
  transform: rotateX(2deg) rotateY(-2deg);
  transition: all 0.4s ease;

  &:hover {
    transform: rotateX(0) rotateY(0) scale(1.02);
    box-shadow: 0 25px 50px rgba(0,0,0,0.15);
  }
`;

const JourneyTimeline = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
`;

const JourneyItem = styled.div`
  display: flex;
  margin-bottom: 2rem;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: all 0.4s ease;
  border-left: 5px solid #000;

  &:hover {
    transform: translateX(15px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.15);
    border-left-color: #333;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const JourneyContent = styled.div`
  flex: 1;
  padding: 0 1.5rem;
`;

const JourneyIcon = styled(FontAwesomeIcon)`
  font-size: 2.5rem;
  color: #000;
  margin-bottom: 1rem;
  opacity: 0.7;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.2) rotate(15deg);
    opacity: 1;
  }
`;

const JourneyTitle = styled.h3`
  color: #1a1a2e;
  font-size: 1.3rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #333;
  }
`;

const JourneyDescription = styled.p`
  color: #34495e;
  font-size: 0.95rem;
  line-height: 1.6;
`;

const SocialServiceSection = styled.section`
  padding: 3rem 2rem;
  background: linear-gradient(135deg, #f9f9f9, #f0f0f0);
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0,0,0,0.1);
  transform-style: preserve-3d;
  transform: rotateX(2deg) rotateY(-2deg);
  transition: all 0.4s ease;

  &:hover {
    transform: rotateX(0) rotateY(0) scale(1.02);
    box-shadow: 0 25px 50px rgba(0,0,0,0.15);
  }
`;

const SocialServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const SocialServiceCard = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: all 0.4s ease;
  border-top: 4px solid #000;

  &:hover {
    transform: translateY(-15px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.15);
    border-top-color: #333;
  }
`;

const SocialServiceIcon = styled(FontAwesomeIcon)`
  font-size: 3rem;
  color: #000;
  margin-bottom: 1.5rem;
  opacity: 0.7;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.2) rotate(15deg);
    opacity: 1;
  }
`;

const SocialServiceTitle = styled.h3`
  color: #1a1a2e;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  transition: color 0.3s ease;

  &:hover {
    color: #333;
  }
`;

const SocialServiceDescription = styled.p`
  color: #34495e;
  font-size: 0.9rem;
  line-height: 1.6;
`;

const LinkedInProfileButton = styled.a`
  display: inline-flex;
  align-items: center;
  background: linear-gradient(45deg, #000, #333);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 2rem;
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.3);
    background: linear-gradient(45deg, #333, #000);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

function About() {
  const professionalJourney = [
    {
      title: 'Product Advocate at Postman',
      period: 'August 2022 - December 2024',
      description: 'Led Power Pass initiative, conducted 70+ webinars, and contributed to company\'s strategic growth.',
      icon: faBriefcase
    },
    {
      title: 'Senior Presales Consultant at Camu',
      period: 'June 2021 - July 2022',
      description: 'Delivered 300+ product demos with 83% attendance rate, closing 14 deals in 8 months.',
      icon: faBriefcase
    },
    {
      title: 'Technical Support at Freshworks',
      period: 'March 2019 - November 2020',
      description: 'Provided technical support, established knowledge base, and initiated community service projects.',
      icon: faBriefcase
    }
  ];

  const socialServices = [
    {
      title: 'No Food Waste Initiative',
      description: 'Collaborated with Chennai Chapter to support local community food collection programs.',
      icon: faHandsHelping
    },
    {
      title: 'Community Engagement',
      description: 'Organized book donation drives and promoted inclusive workplace practices.',
      icon: faHandsHelping
    },
    {
      title: 'Team Culture Building',
      description: 'Implemented cultural changes to enhance team collaboration and morale.',
      icon: faTrophy
    }
  ];

  const skills = [
    {
      icon: faCode,
      title: 'Technical Support',
      description: 'Expert in resolving complex technical issues and providing customer solutions'
    },
    {
      icon: faChartLine,
      title: 'Product Advocacy',
      description: 'Driving product adoption and creating comprehensive documentation'
    },
    {
      icon: faUserTie,
      title: 'Customer Success',
      description: 'Building strong customer relationships and improving user experience'
    }
  ];

  return (
    <AboutContainer>
      <SectionTitle>About Me</SectionTitle>

      <HeroSection>
        <ProfileImageWrapper>
          <ProfileImage 
            src="/profile-pic.jpg" 
            alt="Ashwin Kumar" 
          />
        </ProfileImageWrapper>

        <HeroContent>
          <Name>Ashwin Kumar</Name>
          <Tagline>AI Enthusiast | Trainer | Product Advocate | Customer Success Expert</Tagline>
          <p>
            As a seasoned AI technology consultant and trainer, I specialize in transforming business workflows through cutting-edge AI solutions. With over a decade of experience in technical training and customer success, I offer comprehensive services in AI integration, prompt engineering, and strategic technology implementation. My expertise spans advanced AI tools like <strong>GitHub Copilot</strong>, <strong>ChatGPT</strong>, <strong>Claude AI</strong>, <strong>VSCode</strong> extensions, <strong>Blackbox</strong>, <strong>Cline</strong>, <strong>Cursor</strong>, and <strong>bolt.new</strong>, helping businesses unlock their full potential through intelligent automation and innovative problem-solving. I provide tailored workshops, consulting services, and hands-on training programs designed to empower teams with practical AI skills and strategic insights. My approach combines technical proficiency with a deep understanding of business dynamics, enabling organizations to leverage AI technologies effectively and drive meaningful digital transformation.
          </p>
        </HeroContent>
      </HeroSection>

      <ProfessionalJourneySection>
        <SectionTitle>Professional Journey</SectionTitle>
        <JourneyTimeline>
          {professionalJourney.map((job, index) => (
            <JourneyItem key={index}>
              <JourneyIcon icon={job.icon} />
              <JourneyContent>
                <JourneyTitle>{job.title}</JourneyTitle>
                <JourneyDescription>{job.period}</JourneyDescription>
                <JourneyDescription>{job.description}</JourneyDescription>
              </JourneyContent>
            </JourneyItem>
          ))}
        </JourneyTimeline>
        <LinkedInProfileButton 
          href="https://www.linkedin.com/in/ashwin-kumar-612021124" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} />
          View My Complete Profile
        </LinkedInProfileButton>
      </ProfessionalJourneySection>

      <SocialServiceSection>
        <SectionTitle>Social Services & Community Impact</SectionTitle>
        <SocialServiceGrid>
          {socialServices.map((service, index) => (
            <SocialServiceCard key={index}>
              <SocialServiceIcon icon={service.icon} />
              <SocialServiceTitle>{service.title}</SocialServiceTitle>
              <SocialServiceDescription>{service.description}</SocialServiceDescription>
            </SocialServiceCard>
          ))}
        </SocialServiceGrid>
        <LinkedInProfileButton 
          href="https://mangalabharathitrust.in/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Visit Mangala Bharathi Trust Website
        </LinkedInProfileButton>
      </SocialServiceSection>
    </AboutContainer>
  );
}

export default About;
