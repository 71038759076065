import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: ${props => props.theme.colors.background};
  border-bottom: 1px solid ${props => props.theme.colors.border};
  position: relative;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  border-bottom: none;
  z-index: 1001;
  position: relative;

  @media (max-width: 768px) {
    align-self: flex-start;
    margin-bottom: 1rem;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    display: ${props => props.$isOpen ? 'flex' : 'none'};
    flex-direction: column;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: ${props => props.theme.colors.background};
    padding: 1rem 0;
    border-top: 1px solid ${props => props.theme.colors.border};
    z-index: 1000;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
`;

const NavLink = styled(Link)`
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  border-bottom: 1px solid transparent;
  display: block;
  width: 100%;
  white-space: nowrap;       
  text-overflow: ellipsis; 

  &:hover {
    opacity: 0.7;
    border-bottom-color: ${props => props.theme.colors.text};
    background-color: rgba(0,0,0,0.05);
  }

  @media (max-width: 768px) {
    padding: 1rem;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    white-space: normal; 
  }
`;

const MobileMenuIcon = styled.div`
  display: none;
  cursor: pointer;
  color: ${props => props.theme.colors.text};
  z-index: 1002;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      const navElement = document.querySelector('nav');
      if (navElement && !navElement.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  // Close menu on navigation
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <Nav>
      <NavContainer>
        <Logo to="/" onClick={closeMenu}>Ashwin's Tech Solutions</Logo>
        <MobileMenuIcon onClick={toggleMenu}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="lg" />
        </MobileMenuIcon>
        <NavLinks $isOpen={isOpen}>
          <NavLink to="/" onClick={closeMenu}>Home</NavLink>
          <NavLink to="/about" onClick={closeMenu}>About</NavLink>
          <NavLink to="/services" onClick={closeMenu}>Services</NavLink>
          <NavLink to="/ai-training" onClick={closeMenu}>AI Training</NavLink>
          <NavLink to="/portfolio" onClick={closeMenu}>Portfolio</NavLink>
          <NavLink to="/contact" onClick={closeMenu}>Contact</NavLink>
        </NavLinks>
      </NavContainer>
    </Nav>
  );
}

export default Navbar;
