import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTooth,
  faTruck,
  faHandHoldingHeart,
  faShippingFast,
  faAddressCard
} from '@fortawesome/free-solid-svg-icons';

const PortfolioContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f4f5f7;
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: 3rem;
  color: #1a1a1a;
  font-size: 2.5rem;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 2px;

  &::before {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    width: 100px;
    height: 3px;
    background-color: #000;
    transform: translateX(-50%);
  }
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2.5rem;
`;

const ProjectCard = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  padding: 2.5rem;
  box-shadow: 0 15px 35px rgba(0,0,0,0.1);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #000 0%, #333 100%);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease;
  }

  &:hover {
    transform: translateY(-15px);
    box-shadow: 0 20px 40px rgba(0,0,0,0.15);

    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
`;

const ProjectIcon = styled(FontAwesomeIcon)`
  font-size: 3.5rem;
  color: #000;
  margin-bottom: 1.5rem;
  transition: all 0.4s ease;
`;

const ProjectTitle = styled.h3`
  color: #1a1a1a;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 700;
`;

const ProjectDescription = styled.p`
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.6;
  flex-grow: 1;
`;

const TechStack = styled.div`
  margin-bottom: 1.5rem;
`;

const TechTitle = styled.h4`
  color: #1a1a1a;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const TechList = styled.ul`
  list-style-type: disc;
  padding-left: 1.5rem;
  color: #333;
`;

const ProjectLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const ProjectLink = styled.a`
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;

  &:hover {
    color: #333;
  }
`;

function Portfolio() {
  const projects = [
    {
      icon: faTooth,
      title: "Tara's Dental & Aesthetic Center Website",
      description: "A comprehensive, performance-optimized website for a dental clinic showcasing services and patient care.",
      techStack: [
        'HTML5',
        'CSS3 (Bootstrap framework)',
        'Vanilla JavaScript',
        'Responsive Design',
        'SEO Optimization'
      ],
      keyFeatures: [
        'Performance optimization techniques',
        'Preload critical resources',
        'Lazy loading of images',
        'Owl Carousel for testimonials',
        'Animated sections using AOS',
        'WhatsApp contact integration'
      ],
      liveLink: "https://tarasdental.in/",
      status: "Live"
    },
    {
      icon: faHandHoldingHeart,
      title: "Mangala Bharathi Trust Website",
      description: "A full-stack web application for a non-profit organization with comprehensive event and admin management.",
      techStack: [
        'PHP',
        'MySQL',
        'Bootstrap',
        'Admin Authentication System',
        'Event Management'
      ],
      keyFeatures: [
        'Secure admin panel',
        'Event management system',
        'Team and partner management',
        'Donations management',
        'Responsive design'
      ],
      liveLink: "https://mangalabharathitrust.in/",
      status: "Live"
    },
    {
      icon: faShippingFast,
      title: "Route Revolution - Logistics Tracking Web App",
      description: "A dynamic logistics tracking platform with multiple service modals and real-time tracking functionality.",
      techStack: [
        'HTML5',
        'Vanilla JavaScript',
        'Node.js with Express.js',
        'PHP',
        'Delhivery Tracking API'
      ],
      keyFeatures: [
        'Interactive shipment tracking',
        'Multiple service modals',
        'Real-time tracking system',
        'Responsive design',
        'Secure API integration'
      ],
      liveLink: "https://routerevolution.in/",
      status: "Live"
    },
    {
      icon: faTruck,
      title: "Nalam & Co Logistics Website",
      description: "A custom logistics services website with detailed service sections and clear information presentation.",
      techStack: [
        'HTML5',
        'CSS3',
        'JavaScript',
        'PHP',
        'Responsive Design'
      ],
      keyFeatures: [
        'Custom logistics services showcase',
        'Detailed service sections',
        'Responsive layout',
        'Clear information architecture'
      ],
      liveLink: "https://nalamnco.in/",
      status: "Live"
    },
    {
      icon: faAddressCard,
      title: "Digital Visiting Card Application",
      description: "A full-stack web application for creating and sharing digital business cards with multiple templates.",
      techStack: [
        'Flask (Python)',
        'SQLAlchemy',
        'HTML5',
        'Jinja2 Templating',
        'QR Code Generation'
      ],
      keyFeatures: [
        'User authentication',
        'Multiple card templates',
        'QR code generation',
        'Customizable themes',
        'Responsive design'
      ],
      liveLink: null,
      status: "In Progress"
    }
  ];

  return (
    <PortfolioContainer>
      <SectionTitle>My Portfolio</SectionTitle>

      <ProjectGrid>
        {projects.map((project, index) => (
          <ProjectCard key={index}>
            <ProjectIcon 
              icon={project.icon} 
            />
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectDescription>{project.description}</ProjectDescription>
            
            <TechStack>
              <TechTitle>Tech Stack:</TechTitle>
              <TechList>
                {project.techStack.map((tech, techIndex) => (
                  <li key={techIndex}>{tech}</li>
                ))}
              </TechList>
            </TechStack>

            <TechStack>
              <TechTitle>Key Features:</TechTitle>
              <TechList>
                {project.keyFeatures.map((feature, featureIndex) => (
                  <li key={featureIndex}>{feature}</li>
                ))}
              </TechList>
            </TechStack>

            <ProjectLinks>
              {project.liveLink ? (
                <ProjectLink href={project.liveLink} target="_blank" rel="noopener noreferrer">
                  Visit Website
                </ProjectLink>
              ) : (
                <ProjectLink as="span" style={{color: '#666'}}>
                  {project.status}
                </ProjectLink>
              )}
            </ProjectLinks>
          </ProjectCard>
        ))}
      </ProjectGrid>
    </PortfolioContainer>
  );
}

export default Portfolio;
