import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { 
  faPhone, 
  faEnvelope, 
  faMapMarkerAlt,
  faPaperPlane
} from '@fortawesome/free-solid-svg-icons';

const theme = {
  colors: {
    background: '#ffffff',
    text: '#000000',
    muted: '#333333',
    border: '#e0e0e0',
    hover: '#f0f0f0'
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    fontSize: {
      small: '0.875rem',
      medium: '1rem',
      large: '1.25rem',
      xlarge: '2rem'
    }
  },
  spacing: {
    xs: '0.5rem',
    sm: '1rem',
    md: '1.5rem',
    lg: '2rem',
    xl: '3rem'
  },
  borderRadius: '8px',
  transition: 'all 0.3s ease'
};

const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: ${theme.colors.background};
  font-family: ${theme.typography.fontFamily};
`;

const ContactContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: ${theme.spacing.xl};
  
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: ${theme.spacing.lg};
  }
`;

const ContactInfoSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: ${theme.spacing.xl};
  border-right: 1px solid ${theme.colors.border};

  @media (max-width: 1024px) {
    border-right: none;
    border-bottom: 1px solid ${theme.colors.border};
    padding-right: 0;
    padding-bottom: ${theme.spacing.lg};
    margin-bottom: ${theme.spacing.lg};
  }
`;

const ContactTitle = styled.h1`
  font-size: ${theme.typography.fontSize.xlarge};
  color: ${theme.colors.text};
  margin-bottom: ${theme.spacing.lg};
  font-weight: 700;
`;

const ContactMethodContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${theme.spacing.md};
`;

const ContactMethod = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.md};
  padding: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius};
  transition: ${theme.transition};

  &:hover {
    background-color: ${theme.colors.hover};
  }
`;

const ContactIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.text};
  color: ${theme.colors.background};
  border-radius: 50%;
  font-size: 1.25rem;
`;

const ContactText = styled.div`
  flex-grow: 1;
`;

const ContactLabel = styled.p`
  font-size: ${theme.typography.fontSize.small};
  color: ${theme.colors.muted};
  margin-bottom: 0.25rem;
`;

const ContactValue = styled.a`
  font-size: ${theme.typography.fontSize.medium};
  color: ${theme.colors.text};
  text-decoration: none;
  transition: ${theme.transition};

  &:hover {
    color: ${theme.colors.muted};
  }
`;

const WhatsAppButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  background-color: ${theme.colors.text};
  color: ${theme.colors.background};
  text-decoration: none;
  border-radius: ${theme.borderRadius};
  margin-top: ${theme.spacing.md};
  transition: ${theme.transition};

  &:hover {
    opacity: 0.9;
  }
`;

const AppointmentSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${theme.spacing.xl};

  @media (max-width: 1024px) {
    padding-left: 0;
  }
`;

const AppointmentTitle = styled.h2`
  font-size: ${theme.typography.fontSize.large};
  color: ${theme.colors.text};
  margin-bottom: ${theme.spacing.lg};
  font-weight: 600;
`;

const CalendarContainer = styled.div`
  width: 100%;
  border-radius: ${theme.borderRadius};
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0,0,0,0.1);
`;

function Contact() {
  return (
    <PageContainer>
      <ContactContainer>
        <ContactInfoSection>
          <ContactTitle>Get in Touch</ContactTitle>
          <ContactMethodContainer>
            <ContactMethod>
              <ContactIcon>
                <FontAwesomeIcon icon={faPhone} />
              </ContactIcon>
              <ContactText>
                <ContactLabel>Phone</ContactLabel>
                <ContactValue href="tel:+919884047475">
                  +91 9884047475
                </ContactValue>
              </ContactText>
            </ContactMethod>

            <ContactMethod>
              <ContactIcon>
                <FontAwesomeIcon icon={faEnvelope} />
              </ContactIcon>
              <ContactText>
                <ContactLabel>Email</ContactLabel>
                <ContactValue href="mailto:admin@ashwinai.in">
                  admin@ashwinai.in
                </ContactValue>
              </ContactText>
            </ContactMethod>

            <ContactMethod>
              <ContactIcon>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </ContactIcon>
              <ContactText>
                <ContactLabel>Location</ContactLabel>
                <ContactValue as="span">
                  Chennai, Tamil Nadu, India
                </ContactValue>
              </ContactText>
            </ContactMethod>

            <WhatsAppButton href="https://wa.me/+919884047475" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} />
              Send WhatsApp Message
            </WhatsAppButton>
          </ContactMethodContainer>
        </ContactInfoSection>

        <AppointmentSection>
          <AppointmentTitle>Book an Appointment</AppointmentTitle>
          <CalendarContainer>
            <iframe 
              src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ1T3QeT71NSAkqIXphE_CteTUG5O_cVhXSZt-foLTQKali_zgtgJ01IXpgdkiylgH59uI0JgV_m?gv=true" 
              style={{ 
                border: 0, 
                width: '100%', 
                height: '700px'
              }} 
              frameBorder="0"
            />
          </CalendarContainer>
        </AppointmentSection>
      </ContactContainer>
    </PageContainer>
  );
}

export default Contact;
