import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLinkedin, 
  faFacebook, 
  faWhatsapp, 
  faInstagram 
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const FooterContainer = styled.footer`
  background-color: #333;
  color: white;
  padding: 2rem;
  text-align: center;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1rem;
`;

const SocialIcon = styled.a`
  color: white;
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: #007bff;
    transform: scale(1.2);
  }

  &:nth-child(1) {
    &:hover {
      color: #25D366;
    }
  }

  &:nth-child(2) {
    &:hover {
      color: #3b5998;
    }
  }

  &:nth-child(3) {
    &:hover {
      color: #E1306C;
    }
  }

  &:nth-child(4) {
    &:hover {
      color: #D44638;
    }
  }
`;

const Copyright = styled.div`
  font-size: 0.9rem;
`;

function Footer() {
  return (
    <FooterContainer>
      <SocialLinks>
        <SocialIcon 
          href="https://wa.me/+919884047475" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faWhatsapp} />
        </SocialIcon>
        <SocialIcon 
          href="https://www.facebook.com/rashwincrush/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </SocialIcon>
        <SocialIcon 
          href="https://www.instagram.com/rashwincrush/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </SocialIcon>
        <SocialIcon 
          href="mailto:admin@ashwinai.in" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </SocialIcon>
        <SocialIcon 
          href="https://www.linkedin.com/in/ashwin-kumar-612021124" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </SocialIcon>
      </SocialLinks>
      <Copyright>
        {new Date().getFullYear()} Ashwin's Tech Solutions. All Rights Reserved.
      </Copyright>
    </FooterContainer>
  );
}

export default Footer;
