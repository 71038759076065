import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Add global accessibility attributes
document.documentElement.lang = 'en';
document.body.setAttribute('role', 'document');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div id="app-container" aria-live="polite">
      <App />
    </div>
  </React.StrictMode>
);
