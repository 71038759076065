import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCode, 
  faChartLine, 
  faUserTie,
  faBriefcase,
  faHandsHelping,
  faTrophy,
  faDesktop,
  faRobot,
  faCogs,
  faLightbulb,
  faHeart,
  faLifeRing
} from '@fortawesome/free-solid-svg-icons';

const theme = {
  colors: {
    text: '#333',
    muted: '#666',
    cardBackground: '#f8f9fa',
    border: '#ddd'
  },
  borderRadius: '10px'
};

const HomeContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
`;

const HeroSection = styled.section`
  text-align: center;
  margin-bottom: 4rem;
  background-color: #fff;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  color: ${theme.colors.text};
  margin-bottom: 1rem;
`;

const HeroSubtitle = styled.h2`
  font-size: 1.5rem;
  color: ${theme.colors.muted};
  margin-bottom: 2rem;
`;

const ServiceSection = styled.section`
  margin-bottom: 4rem;
  background-color: #fff;
`;

const SectionTitle = styled.h2`
  text-align: center;
  color: ${theme.colors.text};
  margin-bottom: 2rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 2px;
    background-color: ${theme.colors.text};
  }
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled.div`
  background-color: ${theme.colors.cardBackground};
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.borderRadius};
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }
`;

const ServiceIcon = styled.div`
  font-size: 3rem;
  color: ${theme.colors.text};
  margin-bottom: 1rem;
`;

const ServiceTitle = styled.h3`
  color: ${theme.colors.text};
  margin-bottom: 1rem;
`;

const ServiceDescription = styled.p`
  color: ${theme.colors.muted};
`;

const WhyChooseMeSection = styled.section`
  background-color: ${theme.colors.cardBackground};
  padding: 3rem 2rem;
  border-radius: ${theme.borderRadius};
`;

const WhyChooseMeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const WhyChooseMeItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const WhyChooseMeItemIcon = styled.div`
  font-size: 2.5rem;
  color: ${theme.colors.text};
  margin-bottom: 1rem;
`;

const WhyChooseMeItemTitle = styled.h4`
  color: ${theme.colors.text};
  margin-bottom: 0.5rem;
`;

const WhyChooseMeItemDescription = styled.p`
  color: ${theme.colors.muted};
`;

function Home() {
  const services = [
    {
      title: 'Web Development Services',
      description: 'From simple landing pages to complex web applications, I deliver tailored solutions that help your business thrive in the digital world.',
      icon: faDesktop
    },
    {
      title: 'AI Training & Consultation',
      description: 'Master the latest AI tools with expert training in ChatGPT, Claude AI, and VS Code AI extensions.',
      icon: faRobot
    }
  ];

  const whyChooseMe = [
    {
      title: 'Modern, Responsive Designs',
      description: 'Cutting-edge, mobile-friendly web solutions',
      icon: faCogs
    },
    {
      title: 'Expert AI Integration',
      description: 'Seamless incorporation of AI technologies',
      icon: faLightbulb
    },
    {
      title: 'Performance Optimization',
      description: 'Fast, efficient, and high-performing websites',
      icon: faChartLine
    }
  ];

  return (
    <HomeContainer>
      <HeroSection>
        <HeroTitle>Transforming Ideas into Digital Reality</HeroTitle>
        <HeroSubtitle>Expert Web Development & AI Training Solutions</HeroSubtitle>
      </HeroSection>

      <ServiceSection>
        <SectionTitle>Our Services</SectionTitle>
        <ServiceGrid>
          {services.map((service, index) => (
            <ServiceCard key={index}>
              <ServiceIcon>
                <FontAwesomeIcon icon={service.icon} />
              </ServiceIcon>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
            </ServiceCard>
          ))}
        </ServiceGrid>
      </ServiceSection>

      <WhyChooseMeSection>
        <SectionTitle>Why Choose Me</SectionTitle>
        <WhyChooseMeGrid>
          {whyChooseMe.map((item, index) => (
            <WhyChooseMeItem key={index}>
              <WhyChooseMeItemIcon>
                <FontAwesomeIcon icon={item.icon} />
              </WhyChooseMeItemIcon>
              <WhyChooseMeItemTitle>{item.title}</WhyChooseMeItemTitle>
              <WhyChooseMeItemDescription>{item.description}</WhyChooseMeItemDescription>
            </WhyChooseMeItem>
          ))}
        </WhyChooseMeGrid>
      </WhyChooseMeSection>
    </HomeContainer>
  );
}

export default Home;
