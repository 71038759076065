export const theme = {
  colors: {
    background: "#ffffff",
    text: "#000000",
    cardBackground: "#f9f9f9",
    border: "#e0e0e0",
    accent: "#333333",
    muted: "#666666"
  },
  typography: {
    fontFamily: "'Arial', sans-serif",
    fontSize: {
      small: "0.8rem",
      normal: "1rem",
      large: "1.2rem",
      heading: "1.5rem"
    }
  },
  spacing: {
    small: "10px",
    medium: "20px",
    large: "30px"
  },
  borderRadius: "8px"
};

export const GlobalStyle = `
  body {
    background-color: ${theme.colors.background};
    color: ${theme.colors.text};
    font-family: ${theme.typography.fontFamily};
    margin: 0;
    padding: 0;
    line-height: 1.6;
  }

  * {
    box-sizing: border-box;
    transition: all 0.3s ease;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.text};
    margin-bottom: ${theme.spacing.small};
  }

  a {
    color: ${theme.colors.text};
    text-decoration: none;
    border-bottom: 1px solid ${theme.colors.text};
    transition: opacity 0.3s ease;
  }

  a:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    body {
      font-size: 14px;
    }
  }
`;

export default theme;
