import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCode, 
  faChartLine, 
  faLaptop, 
  faRobot, 
  faNetworkWired,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';

const AITrainingContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #f4f6f9;
`;

const CenteredHeader = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const HeaderTitle = styled.h1`
  font-size: 3rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: 700;
`;

const HeaderSubtitle = styled.p`
  font-size: 1.2rem;
  color: #34495e;
  line-height: 1.6;
`;

const ProgramsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2.5rem;
  margin-top: 2rem;
`;

const ProgramCard = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  padding: 2.5rem;
  box-shadow: 0 15px 35px rgba(0,0,0,0.1);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #000, #333);
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease;
  }

  &:hover {
    transform: translateY(-15px);
    box-shadow: 0 20px 40px rgba(0,0,0,0.15);

    &::before {
      transform: scaleX(1);
      transform-origin: left;
    }

    .program-icon {
      transform: scale(1.1) rotate(10deg);
      color: #000;
    }
  }
`;

const ProgramIcon = styled(FontAwesomeIcon)`
  font-size: 3.5rem;
  color: #000;
  margin-bottom: 1.5rem;
  transition: all 0.4s ease;
`;

const ProgramTitle = styled.h2`
  color: #2c3e50;
  margin-bottom: 1.2rem;
  font-size: 1.8rem;
  font-weight: 600;
`;

const ProgramDescription = styled.p`
  color: #34495e;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.6;
`;

const ProgramFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
`;

const ProgramFeature = styled.li`
  background-color: #f8f9fa;
  margin-bottom: 0.75rem;
  padding: 1rem;
  border-radius: 8px;
  color: #2c3e50;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e9ecef;
    overflow: visible;
    z-index: 10;
  }

  @media (max-width: 768px) {
    white-space: normal;
  }
`;

const FeatureIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
  color: #000;
`;

function AITraining() {
  const trainingPrograms = [
    {
      icon: faRobot,
      title: 'AI & Chatbot Tools Training',
      description: 'Unlock the full potential of AI-powered conversational technologies.',
      features: [
        'ChatGPT Mastery: Advanced conversational interface techniques',
        'Claude AI & Perplexity: Insights generation and task automation',
        'API Integration: Seamless ChatGPT/Claude AI implementation'
      ]
    },
    {
      icon: faCode,
      title: 'Developer Productivity',
      description: 'Enhance development workflows and collaboration strategies.',
      features: [
        'GitHub & VS Code Mastery: Copilot and extension optimization',
        'CI/CD Fundamentals: Continuous integration pipelines',
        'Process Improvement: Identifying and resolving workflow bottlenecks'
      ]
    },
    {
      icon: faChartLine,
      title: 'Data Analysis & Visualization',
      description: 'Transform complex data into actionable business insights.',
      features: [
        'Postman API Training: Workspace and API management',
        'Data Visualization: Simplifying complex datasets',
        'Strategic Decision Making: Data-driven insights generation'
      ]
    },
    {
      icon: faLaptop,
      title: 'AI Strategy & Innovation',
      description: 'Develop transformative AI strategies for business growth.',
      features: [
        'AI Strategy Development: Actionable business transformation roadmaps',
        'Innovation Techniques: Fostering creativity with AI',
        'Custom AI Solutions: Tailored to your business goals'
      ]
    },
    {
      icon: faNetworkWired,
      title: 'Workshops & Hands-On Learning',
      description: 'Practical, immersive training experiences.',
      features: [
        'VS Code Extensions: Beginner to advanced techniques',
        'Custom Workshops: Tailored to team-specific challenges',
        'AI Implementation Bootcamp: Practical tool applications'
      ]
    }
  ];

  return (
    <AITrainingContainer>
      <CenteredHeader>
        <HeaderTitle>AI Training Programs</HeaderTitle>
        <HeaderSubtitle>
          Empower your team with essential and practical tech skills. Our tailored training 
          programs are designed to equip professionals with industry-relevant expertise 
          and drive innovation across technological domains.
        </HeaderSubtitle>
      </CenteredHeader>

      <ProgramsGrid>
        {trainingPrograms.map((program, index) => (
          <ProgramCard key={index}>
            <ProgramIcon 
              icon={program.icon} 
              className="program-icon"
            />
            <ProgramTitle>{program.title}</ProgramTitle>
            <ProgramDescription>{program.description}</ProgramDescription>
            <ProgramFeatures>
              {program.features.map((feature, featureIndex) => (
                <ProgramFeature key={featureIndex}>
                  <FeatureIcon icon={faArrowRight} />
                  {feature}
                </ProgramFeature>
              ))}
            </ProgramFeatures>
          </ProgramCard>
        ))}
      </ProgramsGrid>
    </AITrainingContainer>
  );
}

export default AITraining;
