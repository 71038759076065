import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCode, faLaptop, faRobot, faInfoCircle, faTag, faShare } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const theme = {
  colors: {
    text: '#000',
    cardBackground: '#fff',
    border: '#000',
    muted: '#333',
    primary: '#000'
  },
  borderRadius: '10px'
};

const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
`;

const SectionTitle = styled.h1`
  text-align: center;
  color: #000;
  margin-bottom: 2rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 2px;
    background-color: #000;
  }
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  }
`;

const ServiceTitle = styled.h2`
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
`;

const ServiceDescription = styled.p`
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
`;

const ServicePrice = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
`;

const ServiceFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ServiceFeature = styled.li`
  margin-bottom: 0.5rem;
  color: #000;
  display: flex;
  align-items: center;

  svg {
    color: #000;
    margin-right: 0.5rem;
  }
`;

const ServiceDetails = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  font-size: 0.9rem;
  line-height: 1.6;
  color: #000;
  border-left: 4px solid ${theme.colors.primary};
`;

const ServiceAdditionalInfo = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f4f8;
  border-radius: 8px;
  padding: 12px;
  margin-top: 15px;
  font-size: 0.85rem;
  color: #000;
  
  svg {
    margin-right: 10px;
    color: #000;
  }
`;

const SocialShareContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  gap: 15px;
`;

const SocialShareButton = styled.a`
  color: #000;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #666;
  }
`;

function Services() {
  const services = [
    {
      icon: faCode,
      title: 'Basic Business Presence',
      description: 'Establish your online identity with a professional digital footprint.',
      price: 'Starts @ Rs 8,000',
      features: [
        'Single-page website design',
        'Mobile responsive layout',
        'Basic SEO optimization',
        'Contact form integration',
        'Social media links'
      ],
      details: 'Perfect for small businesses and startups looking to establish an initial online presence. Includes a clean, modern single-page website that showcases your brand, services, and contact information.',
      additionalInfo: 'Hosting and domain charges extra. 30 days free support after deployment.'
    },
    {
      icon: faLaptop,
      title: 'Professional Web Deployment',
      description: 'Comprehensive web solutions for growing businesses.',
      price: 'Starts @ Rs 20,000',
      features: [
        'Multi-page responsive website',
        'Advanced SEO optimization',
        'Custom design and branding',
        'Content management system',
        'Performance optimization'
      ],
      details: 'A full-featured website solution designed to elevate your online presence. Includes custom design, multiple pages, and advanced features to showcase your business effectively.',
      additionalInfo: 'Hosting and domain charges extra. 30 days free support after deployment.'
    },
    {
      icon: faRobot,
      title: 'AI-Powered Digital Strategy',
      description: 'Leverage cutting-edge AI technologies for business growth.',
      price: 'Starts @ Rs 50,000',
      features: [
        'AI-driven website optimization',
        'Chatbot integration',
        'Personalized user experience',
        'Advanced analytics',
        'Machine learning insights'
      ],
      details: 'Transform your digital strategy with AI-powered solutions. Includes intelligent chatbots, personalized user experiences, and data-driven insights to drive business growth.',
      additionalInfo: '60 days free support after deployment.'
    }
  ];

  const shareUrl = 'https://ashwinai.in/services';
  const shareTitle = 'Explore Ashwin AI Services - Digital Solutions & AI Strategies';

  const getSocialShareLinks = (service) => {
    const encodedTitle = encodeURIComponent(`${service.title} - ${shareTitle}`);
    return {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodeURIComponent(shareUrl)}`,
      linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodedTitle}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodedTitle}%20${encodeURIComponent(shareUrl)}`
    };
  };

  return (
    <ServicesContainer>
      <SectionTitle>Services & Pricing</SectionTitle>
      <ServiceGrid>
        {services.map((service, index) => {
          const socialLinks = getSocialShareLinks(service);
          return (
            <ServiceCard key={index}>
              <FontAwesomeIcon icon={service.icon} size="2x" style={{ marginBottom: '1rem', color: '#000' }} />
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
              <ServicePrice>{service.price}</ServicePrice>
              <ServiceFeatures>
                {service.features.map((feature, featureIndex) => (
                  <ServiceFeature key={featureIndex}>
                    <FontAwesomeIcon icon={faCheck} />
                    {feature}
                  </ServiceFeature>
                ))}
              </ServiceFeatures>
              <ServiceDetails>
                <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '10px', color: '#000' }} />
                {service.details}
              </ServiceDetails>
              {service.additionalInfo && (
                <ServiceAdditionalInfo>
                  <FontAwesomeIcon icon={faTag} style={{ marginRight: '10px', color: '#000' }} />
                  {service.additionalInfo}
                </ServiceAdditionalInfo>
              )}
              <SocialShareContainer>
                <SocialShareButton href={socialLinks.facebook} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebook} size="lg" />
                </SocialShareButton>
                <SocialShareButton href={socialLinks.twitter} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTwitter} size="lg" />
                </SocialShareButton>
                <SocialShareButton href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} size="lg" />
                </SocialShareButton>
                <SocialShareButton href={socialLinks.whatsapp} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                </SocialShareButton>
              </SocialShareContainer>
            </ServiceCard>
          );
        })}
      </ServiceGrid>
    </ServicesContainer>
  );
}

export default Services;
